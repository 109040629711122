import { signInWithEmailAndPassword } from "firebase/auth"
import { useEffect, useState, useContext } from "react"
import { auth } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"
import { navigate } from "gatsby"
import { StateContext } from "../helpers/StateProvider"
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   documentId,
// } from "firebase/firestore"
// import { db } from "../firebase/config"
export const useLogin = () => {
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()
  const [messageerror, setMessageerror] = useContext(StateContext)
  // const fetchNav = async name => {
  //   const q = query(collection(db, "users"), where(documentId(), "==", name))
  //   const querySnapshot = await getDocs(q)
  //   let arr = {}
  //   querySnapshot.forEach(doc => {
  //     arr = doc.data()
  //   })
  //   return arr
  // }

  const login = async (email, password) => {
    try {
      setIsPending(true)
      // sign in and dispatch
      const cred = await signInWithEmailAndPassword(auth, email, password)
      // dispatch
      console.log("cred", cred.user.uid)
      // const data = fetchNav(cred.reloadUserInfo.localId)
      // if (data) {
      //   console.log("cred", data)
      // }
      await dispatch({ type: "LOGIN", payload: cred.user })

      setIsPending(false)
      return cred.user.uid
    } catch (err) {
      navigate("/login/")
    }
  }

  useEffect(() => {
    return () => {
      setIsPending(false)
    }
  }, [])

  return { login, isPending }
}
