import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useState } from "react"

import { Link } from "gatsby"

import {
  Container,
  ImageContainer,
  RightContent,
  LoginContent,
  PasswordShowContainer,
  NoAccount,
} from "./Login.styles"
import PasswordShow from "../../assets/icons/password-show"
import { navigate } from "gatsby"

// firebase hook
import { useLogin } from "../../hooks/useLogin"

import Loader from "../Loader"

const LoginComponent = ({ message }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState(false)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [messageerror, setMessageerror] = useState("")

  const { login, isPending } = useLogin()
  const signIn = () => {
    login(email, password).then(id => {
      navigate("/")
    })
  }

  return (
    <Container>
      {isPending && <Loader />}
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <LoginContent
              emailFocused={emailFocus}
              passwordFocused={passwordFocus}
            >
              <h2>Welcome Back!</h2>
              <h6>Please enter username and password in order to login</h6>
              <div className="email-container">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter Email Address"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                />
              </div>
              <div className="password-container">
                <label htmlFor="password">Password*</label>
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  onChange={e => setPassword(e.target.value)}
                  onFocus={() => setPasswordFocus(true)}
                  onBlur={() => setPasswordFocus(false)}
                />
                <PasswordShowContainer
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <PasswordShow passwordShow={passwordVisible} />
                </PasswordShowContainer>
              </div>
              <div>{message}</div>
              <div className="forgot-content">
                <Link to="/">FORGOT PASSWORD?</Link>
              </div>
              <button className="submit-btn" onClick={signIn}>
              Stay Connected
              </button>
              <hr />
            </LoginContent>
            <NoAccount>
              <h5>Don't have an account?</h5>
              <p>
                By creating an account, you will receive updates and special
                offers. You may unsubscribe at any time.
              </p>
              <Link to="/register" className="create">
              Join The Club
              </Link>
            </NoAccount>
          </div>
        </div>
      </div>
      <RightContent>
        <ImageContainer>
          <StaticImage
            src="../../assets/images/login-image.png"
            alt="login image"
            placeholder="tracedSVG"
            layout="fullWidth"
            className="login-image"
          />
        </ImageContainer>
      </RightContent>
    </Container>
  )
}

export default LoginComponent
