import styled from "styled-components";

export const Container = styled.div`
    padding: 40px 0;
`;

export const RightContent = styled.div`
    position: absolute;
    top:0;
    bottom: 0;
    right:0;
    background-color: var(--darkBlue);
    width: 30%;
    height: 100%;
    z-index: -9;

    @media (max-width: 992px){
        display: none;
    }
`;

export const ImageContainer = styled.div`
    height: 100%;
    position: relative;
    width: 100%;

    .login-image{
        width: 155%;
        max-width: 800px;
        position: absolute;
        right:0;
        top: 50vh;
        transform:translateY(-50%);
    }
`;

export const LoginContent = styled.div`
    h2{
        font-weight: bold;
    }

    h6{
        margin-bottom: 30px;
        font-weight: normal;
    }

    .email-container,.password-container{
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        width: 100%;
        position: relative;
        margin-bottom: 20px;
        height:46px;

        label{
            display: block;
            margin-bottom: 5px;
            color: var(--lightGreen);
            position: absolute;
            font-size:0.9rem;
            transition: 0.2s ease;
        }

        input{
            height: 100%;
            width: 100%;
            border:none;

            &:focus{
                outline: none;
            }
        }
    }

    .email-container{
        padding:${({ emailFocused }) => emailFocused ? '0 10px' : '20px 10px 0px'};
        
        label{
            top:${({ emailFocused }) => emailFocused ? '-20px' : '0'};
        }
    }

    .password-container{
        input{
            padding:${({ passwordFocused }) => passwordFocused ? '0 10px' : '20px 10px 0px'};
        }
        padding:0 10px;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        label{  
            top:${({ passwordFocused }) => passwordFocused ? '-20px' : '0'}
        }
    }

    .forgot-content{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;

        a{
            color: var(--lightGreen);
            text-decoration: none;
            font-size: 0.9rem;
        }
    }

    .submit-btn{
        width: 100%;
        background-color: var(--lightGreen);
        border: none;
        color: white;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
    }
`;

export const PasswordShowContainer = styled.div`
    padding: 1px;
`;

export const NoAccount = styled.div`
    padding: 20px 0;

    .create{
        display: block;
        text-align: center;
        width: 100%;
        padding: 10px;
        border: 1px solid var(--lightGreen);
        color: var(--lightGreen);
        text-decoration: none;
        border-radius: 5px;
        margin-bottom: 30px;

        &:hover{
            color: var(--lightGreen);
        }
    }
`;